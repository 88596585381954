












.print-btn{
    background-color: #2557ea;
    padding: 10px 20px;
    color: white;
    font-weight: bolder;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    font-size: 16px;

}
.view-print{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    /* position: absolute; */
}

@media print{
    .print-body{
        width: 1240px;
    }
    .view-main{
    width: 1240px ;
    padding: 40px!important;


    }
}

.view-main{
    background-color: white;
    /* height: 1754px ; */
    /* height: 100em ; */
    line-height:18px!important;

    /* height: 100% ; */
    min-height: 1000px;
    margin: auto;
    box-sizing: border-box;
    padding: 20px;
    page-break-after: always;

}
.view-body{
    /* background-color: red; */
    height: 100%;
}




.one{
    display: flex;
    min-height: 300px;

}
.one-left{
    /* background-color: red; */
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;



}
.one-left-table{
    height: 100%;
    width: 100%;
    border-collapse: collapse;

}
table ,tr,th ,td{
    border: 1px solid lightgrey;
}
.one-left-table tr td,.one-left-table tr th{
    border: 2px solid black;

}

.one-right{
    /* background-color: blue; */
    /* border: 1px solid black; */
    flex: 4;


}
.one-left-table tr td{
    padding: 7px;
    text-align: center;
}
.one-left-table tr td img{
max-height: 200px;
}