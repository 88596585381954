*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
}
body{
  /* background-color: #e5e5e5; */
}
:root{
  /* --pr-color:#53cfcd; */
  /* --sc-color:#0253a2; */
  --pr-color:#6bc9d6;
  --sc-color:#0ac2db;
  --pr-text-color:white;
  --clr-hover-DropdownLink:white;
  --clr-dark-secondary:#6bc9d6;

}


.custom-table-ant th{
  background-color:var(--pr-color) !important;
  color:white!important;
  border: none!important;
}
.custom-table-with-low-padding th,.custom-table-with-low-padding tr,.custom-table-with-low-padding td{
  padding: 5px!important;

}
.item-table-custom-button span{
font-size: 14px;  
padding: 0px;
}
.custom-table-ant tr,.custom-table-ant td,.custom-table-ant table{
  border: none!important;
}
h1{
  /* margin-top: 100px; */
  margin-bottom: 50px;
  /* text-align: center; */
}
@media (max-width: 1500px) {
  .for-etable{
      width: 90%!important;
      /* font-size: 12px; */
  }

.ant-table-measure-row{
  /* display: none!important; */
}
}
@media (max-width: 1000px) {

  .for-etable tr td,.for-etable tr th{
    /* width: 90%!important; */
    font-size: 0.8rem;
    /* color: red; */
    padding: 8px!important;
}

.li-sidebar{
  font-size: 1rem!important;
}
}


@media screen and (min-width: 700px) {
  .for-visible-img {
    height: 100%;
  }

}
@media screen and (max-width: 700px) {

  .p-item-itemForm div{
    margin-bottom: 0px!important;
  }
}

.hover:hover
{
  cursor: pointer;
}
.ant-table-cell span{
  font-size: 14px!important;
}

.body-width{
width: 1000px;
}
.body-width-vw{
width: 90vw;
margin: auto;
}

.FaEye{
  cursor: pointer;
}
.FaEye{
  cursor: pointer;
}
.odd-row{
  /* background-color: #c8d8e7; */
}
.ant-table-filter-trigger::hover{
  background-color: var(--pr-color)!important;

}
.ant-table-filter-trigger  {
background-color: var(--pr-color)!important;
border: none!important;
color: white!important;
}

.model-main-login{
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 100;
}
.model-main-box-login{
position: relative;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
width: 350px;
padding: 10px;
height: 600px;
background-color: white;
}
.none{
  display: none;
}

.anticon-delete svg{
  color: red !important;
  font-size: 1.8rem;
}

.ant-upload-list-item-file{
  line-height: 0px !important;
}