.pie-char-custom{
    display: flex;
    width: 100%;
    margin: 50px 0px;
    margin: auto;
    justify-content: space-between;
}
@media (max-width: 750px) {
    .pie-char-custom{
     flex-direction: column;
     width: 100vw;
    }
    .pie-char-inside{
        width: 100%!important;
    margin: 0px!important;
    /* height: 300px!important; */

    }
    .pie-char-inside canvas{
    height: 300px!important;

    }
}

.pie-char-inside{
    width: 100%;
    margin: 30px;
    box-shadow: 0px 0px 8px grey inset;
    border-radius: 7px;
    padding: 10px;
}

.h2-pie-char{
    background-color: var(--pr-color);
    color: white;
    padding: 10px;
}