.caraousal-res {
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgSlider {
    width: 95%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    background-image: url("../../assets/image8.jpeg");
    background-size: cover;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    animation: changeImage 20s linear infinite;
}

@keyframes changeImage {
    0% {
        background-image: url("../../assets/image8.jpeg");
    }
    25% {
        background-image: url("../../assets/image7.jpeg");
    }
    50% {
        background-image: url("../../assets/image8.jpeg");
    }
    50% {
        background-image: url("../../assets/image7.jpeg");
    }
    100% {
        background-image: url("../../assets/image8.jpeg");
    }
}
