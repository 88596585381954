.user-profile {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Profile Image */
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Profile Details */
  .profile-details {
    flex-grow: 1;
  }
  
  /* Heading */
  .profile-details h2 {
    margin-bottom: 10px;
    color: var(--pr-color); /* Use theme color for the heading */
    font-size: 24px; /* Larger font size for the heading */
  }
  
  /* List */
  .profile-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .profile-details li {
    margin-bottom: 5px;
    font-size: 18px; /* Larger font size for list items */
  }
  
  /* Strong Element */
  .profile-details li strong {
    font-weight: bold;
  }
  
  /* Theme Color for Buttons */
  .button {
    background-color: var(--pr-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px; /* Larger font size for buttons */
  }
  
  .button:hover {
    background-color: darken(var(--pr-color), 10%);
  }