.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 20px;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;

  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-float {
  margin-top: 1px;
  color: #25d366;
  font-size: 6rem;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10%;

  /* box-shadow: 2px 2px 3px #999; */
}