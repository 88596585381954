.for-etable{
    width: 70%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
}
.e-table-h2{
    background-color: var(--pr-color);
    margin: 50px 0px;
    padding: 20px 15px;
    font-weight: bolder;
    border-radius: 7px;
    color: white;
}
.edit-link {
    color: var(--pr-color); /* Red color for "Edit" link */
  }
.view-link {
    color: var(--pr-color); /* Red color for "Edit" link */
  }
button{
    height: 100%!important;
}