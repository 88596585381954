.model-con{
    position: fixed;
    left: 0;
    right: 0;top: 0;
    bottom: 0;
    background-color: rgba(189, 189, 189, 0.9);
    z-index: 3;
}
.from-row{
    justify-content: space-between!important;
    width: 100%!important;
}
.ant-space-item{
    width: 100%!important;

}
*{
    box-sizing: border-box;
}
.model-box{
    background-color: white;
    position: fixed;
    left: 50%;
    padding: 50px;
    /* overflow: auto; */
    top: 50%;
    transform: translate(-50%,-50%);
}
.model-btn{
    width: 100%;
}
.model-cross{
    position: absolute;
    right: -20px;
    top: -20px;
    width: 50px;
    border-radius: 360px;
    cursor: pointer;
}
.vendor-form-wrapper{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
@media (max-width: 1500px) {
    .model-box{
        /* left: 50%; */
        /* padding: 100px; */
        /* top: 50%; */
        /* transform: translate(-50%,-50%); */
    }
    .model-cross{

        position: absolute;
        right: 81px;
        z-index: 5;
        top: 169px;
        width: 42px;
        border-radius: 360px;
        cursor: pointer;
    }
    .w-cus-moblie{
        /* width: 100px!important; */
    }

  }
@media (max-width: 1000px) {
    .model-box{
        /* left: 50%; */
        width: 100%!important;
        padding: 20px 20px;
        /* top: 50%; */
        height: 100vh;
        /* transform: translate(-50%,-50%); */
    }
    .model-cross {
        position: absolute;
        right: 4px;
        z-index: 5;
        top: 91px;
        width: 42px;
        border-radius: 360px;
        cursor: pointer;
    }
.quotation-form-list{
    display: flex;
    flex-direction: column!important;
}
.w-cus-moblie{
    width: 200px!important;
}
  }

  .p--cross--custom{
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    background: red;
    height: 40px;
    width: 40px;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 12px;
    border-radius: 360px;
}
.p--cross--custom span svg{
 
    color: white;
  
}


@media (max-width: 768px) {
    .model-box{
        /* left: 50%; */
        width: 90%!important;
        padding: 20px 20px;
        /* top: 50%; */
        height: 100%;
        /* transform: translate(-50%,-50%); */
    }
    .model-cross {
        position: absolute;
        right: 4px;
        z-index: 5;
        top: 91px;
        width: 42px;
        border-radius: 360px;
        cursor: pointer;
    }
.quotation-form-list{
    display: flex;
    flex-direction: column!important;
}
.w-cus-moblie{
    width: 200px!important;
}
  }

  .p--cross--custom{
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    background: red;
    height: 40px;
    width: 40px;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 12px;
    border-radius: 360px;
}
.p--cross--custom span svg{
 
    color: white;
  
}