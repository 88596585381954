.main-nav {
  width: 100%;
  /* height: 10rem; */
  display: grid;
  /* grid-template-columns: 5rem 1fr 2fr 1fr 5rem; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 25px 50px -20px,
    rgba(0, 0, 0, 0.3) 0px 15px 30px -30px;
}

.main-nav li {
  list-style: none;
}

.logo {
  display: grid;
  /* background-color: #3b5998; */
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
  width: 190px;
  height: 100px;

}

.logo .logo-spacemate {}

.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media {
  grid-column: 4/5;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 2rem; */
}



.nav-icons {
  font-size: 1.6rem;
}
.conatct-section{
 display: flex;
 flex-direction: column;
 align-items: flex-end;
 justify-content:end ; 
}
.social-media ul {
  height: 4rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
  place-content: center;
  place-items: center;
}
.social-media-call{
  width: 100%;
}
.social-media ul li {
  text-align: right;
  padding: 0 .5em;

}


.social-media ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(rgb(172, 250, 249), rgba(83, 207, 205, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}

.menu-link ul li {
  font-size: 1.1rem;
}

.menu-link ul li a {
  text-transform: capitalize;
  color: #29384F;
  transition: 0.5s;
  word-spacing: 0px;
  font-size: 1.4rem;
  padding: 0 .5rem;
}

.menu-link ul li:hover>a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: underline;
  transition: 0.5s;
}

.social-media ul li {
  font-size: 1.4rem;

}

.social-media .hamburger-menu {
  display: none;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #c32aa3;
}

.youtube {
  color: #ff0000;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
 
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }
  .social-media-call{
    display: none;
  }
 

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }
  .logo {
    /* margin-top: 2rem; */
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}









/* .navbar-bg {
    background-color: var(--bgcolor);
    padding: 2rem 0;
    z-index: 99;
    position: relative;
  } 

  .nav-link {
    color: var(--text-color);
  }
  
  .navbar-brand {
    font-size: 2.5rem;
    padding: 0rem 2rem;
  }
  
  .navbar li {
    padding: 0 2rem;
  }
  
  .navbar li a {
    font-size: 1.7rem;
    font-weight: lighter;
    position: relative;
  }
  
  .navbar li a:hover {
    color: var(--primary-color) !important;
    text-shadow: 0 0.2rem 0.3rem rgba(183, 171, 255, 0.5);
  }


  
.navbars{
    background-color: transparent;
}
  
.navbar.colorChange{
    background-color: #000;
}


  */