:where(.css-dev-only-do-not-override-1m62vyb).ant-form-vertical .ant-form-item-label, :where(.css-dev-only-do-not-override-1m62vyb).ant-col-24.ant-form-item-label, :where(.css-dev-only-do-not-override-1m62vyb).ant-col-xl-24.ant-form-item-label {
    padding: 0;
    white-space: initial;
    text-align: start;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    margin-bottom: 7px;
    vertical-align: top;
}

/* vendorForm.css */

/* For screens larger than 768px */
  
@media (max-width: 768px) {
    .form-box {
      display: flex;
      flex-direction: column;
      max-height: 90vh; /* Ensure the form box doesn’t exceed viewport height */
      overflow-y: auto; /* Add vertical scrollbar if needed */
    }
  
    .form-box .ant-row {
      display: flex;
      flex-direction: column;
    }
  
    .form-box .ant-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    /* Optional: Add padding to the form box to ensure content doesn’t touch edges */
    .form-box {
      padding: 16px;
    }
  }
  