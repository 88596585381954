.login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}
.login-box{
    background-color: white;
    box-shadow: 3px 4px 23px lightgray;
    width: 500px;
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
}